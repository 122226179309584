<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-select v-model="monthInfo.value" placeholder="请选择拦河闸" size="mini">
            <el-option
              v-for="(item,index) in waterList"
              :key="item.deviceAddress"
              :label="index+1+' '+item.siteName"
              :value="item.siteName">
            </el-option>
          </el-select>
        <!-- <el-input
          placeholder="请输入拦河闸名称"
          v-model="monthInfo.value"
        ></el-input> -->
        <el-date-picker
          :editable="false"
          v-model="monthInfo.time"
          type="year"
          placeholder="选择年份"
          class="check_date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="handleSearch(0)">查询</el-button>
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleExport">导出</el-button>
      </div>
    </div>
    <div class="columnarChart" id="columnarChart"></div>
    <div ref="scroll" @mousewheel="MouseWheel">
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
      <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column
          prop="stationName"
          label="拦河闸名称"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          v-for="col in cols"
          :prop="col.prop"
          :label="col.label"
          :key="col.prop"
        >
        </el-table-column>
        <el-table-column width='120' prop="sumRainfall" label="总降雨量(mm)"> </el-table-column>
      </el-table>
      <!-- <el-pagination
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.page"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import { rainYear,rainMonth,siteGetSiteAll,FlowList } from "../../../api/index";
import { yearList } from "../index";
let scrollNum = 0;
export default {
  components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      value: "",
      waterList: [],//拦河闸下拉
      owTrafficList: [], // 后台返回的今年流量表
      tableData: [],
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0
      },
      time: "",
      monthInfo: {
        time: "",
        value: ""
      },
      cols: yearList,
      loading: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 图表
    init (now, year,name) {
      const columnarChart = document.getElementById('columnarChart')
      const myChart = this.$echarts.init(columnarChart)
      const option = {
        legend: {
          show: true,
          right: '5%',
          top: '0%',
          orient: 'horizontal',
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 14,
            color: '#D1D1D1'
          }
        },
        xAxis: {
         // name:"月",
           nameTextStyle:{
            fontSize:10,  
        },
          type: 'category',
          data:[
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
          axisLabel: {
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name:"",//单位
           nameTextStyle:{
            fontSize:10,  
        },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          }
        },
        grid: {
          top: 30,
          x: 58,
          x2: 30,
          y2: 35
        },
        tooltip: {
          trigger: 'axis',
          formatter: name+' <br> {b0} ：{c0} mm'
        },
        series: [
          {
        //    name: '今年',
            data: now,
            type: 'bar',
            stack: 'year',
            emphasis: {
              focus: 'series'
            },
            barWidth: '57%',
            label: {
              show: false
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    fontSize: 12,
                    fontWeight: 'bold'
                  }
                },
                color: '#8270fc'
              }
            }
          },
        ]
      }
      myChart.setOption(option)
      // 图表自适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // getData (val) {
    //   FlowList(val).then(res => {
    //     console.log(res)
    //     // 今年流量表
    //     this.nowTrafficList = res.data.nowTraffic
    //     this.init(
    //       this.nowTrafficList.map(item => item.sumTraffic),
    //       res.data.nowTraffic.map(item => item.month))
    //   })
    // },
     MouseWheel(e) {
      scrollNum += e.deltaY
      this.$refs.scroll.children[0].children[2].scrollTo(scrollNum, 0);
    },
    getList() {
      this.loading = true;
      let { page, size } = this.pagination;
      let date = new Date();
      this.time =
        date.getFullYear() +
        "-" +
        (date.getMonth() - 0 + 1) +
        "-" +
        date.getDate();
      this.monthInfo.time = this.time;
      rainYear({
        page,
        size,
        time: this.time
      }).then(res => {
        this.loading = false;
        this.pagination.total = res.data.total;
        this.tableData = res.data.list.map(item => {
          let num = item.dayRainVos.length;
          let obj = {};
          obj.stationName = item.rainName;
          item["dayRainVos"].forEach(items => {
            obj["rainMonth" + items.rainMonth] = items["rainfall"];
            obj["sumRainfall"] = item["sumRainfall"];
          });
          return obj;
        });
         const arr = res.data.list[0].dayRainVos
            const name =res.data.list[0].dayRainVos[0].rainName
            const nowList = arr.map(item => item.rainfall)
            const nowName = arr.map(item => item.rainDay)
            const max = Math.max(...nowList)
            const min = Math.min(...nowList)
            this.init(
            nowList,nowName,name
              )
      });
    },
    // 分页
    handleCurrent(pages) {
      this.loading = true;
      this.pagination.page = pages;
      let { page, size } = this.pagination;
      let { value, time } = this.monthInfo;
      if (value != "" || time != "") {
        rainYear({
          page,
          size,
          rainName: value,
          time
        }).then(res => {
          this.loading = false;
          this.pagination.total = res.data.total;
          this.tableData = res.data.list.map(item => {
            let num = item.dayRainVos.length;
            let obj = {};
            obj.stationName = item.rainName;
            item["dayRainVos"].forEach(items => {
              obj["rainMonth" + items.rainMonth] = items["rainfall"];
              obj["sumRainfall"] = item["sumRainfall"];
            });
            return obj;
          });
          const arr = res.data.list[0].dayRainVos
            const name =res.data.list[0].dayRainVos[0].rainName
            const nowList = arr.map(item => item.rainfall)
            const nowName = arr.map(item => item.rainDay)
            const max = Math.max(...nowList)
            const min = Math.min(...nowList)
            this.init(
            nowList,nowName,name
              )
        });
      } else {
        rainYear({
          page,
          size
        }).then(res => {
          this.loading = false;
          this.pagination.total = res.data.total;
          this.tableData = res.data.list.map(item => {
            let num = item.dayRainVos.length;
            let obj = {};
            obj.stationName = item.rainName;
            item["dayRainVos"].forEach(items => {
              obj["rainMonth" + items.rainMonth] = items["rainfall"];
              obj["sumRainfall"] = item["sumRainfall"];
            });
            return obj;
          });
           const arr = res.data.list[0].dayRainVos
            const name =res.data.list[0].dayRainVos[0].rainName
            const nowList = arr.map(item => item.rainfall)
            const nowName = arr.map(item => item.rainDay)
            const max = Math.max(...nowList)
            const min = Math.min(...nowList)
            this.init(
            nowList,nowName,name
              )
        });
      }
    },
    // 搜索
    handleSearch(type) {
      this.loading = true;
      if (type == 1) {
        this.$set(this.monthInfo, "value", this.waterList[0].siteName);
        this.$set(this.monthInfo, "time", "");
        this.pagination.page = 1;
        this.getList();
      } else {
        let { value, time } = this.monthInfo;
        if (time == "") {
          this.$message({
            message: "请选择查询内容",
            type: "error"
          });
          return;
        }
        this.time = time;
        rainYear({
          page: 1,
          size: 10,
          rainName: value,
          time
        }).then(res => {
          this.loading = false;
          if (res.code == 200) {
            if (res.data) {
              this.pagination.total = res.data.total;
              this.tableData = res.data.list.map(item => {
                let obj = {};
                obj.stationName = item.rainName;
                item["dayRainVos"].forEach(items => {
                  obj["rainMonth" + items.rainMonth] = items["rainfall"];
                  obj["sumRainfall"] = item["sumRainfall"];
                });
                return obj;
              });
               const arr = res.data.list.find((name)=>{
                          return  name.rainName==value
                          })
              const name =arr.rainName
              const nowList = arr.dayRainVos.map(item => item.rainfall)
                const nowName = arr.dayRainVos.map(item => item.rainDay)
              const max = Math.max(...nowList)
              const min = Math.min(...nowList)
              this.init(
              nowList,nowName,name
                )
            } else {
              this.tableData = [];
            }
          }
        });
      }
    },
    // 导出
    handleExport() {
      let { value, time } = this.monthInfo;
      if (time !== "") {
        fetch(
          this.$axios.defaults.baseURL +
            `rainfallRegime/year/export?time=${time}&rainName=${value}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "雨情年报表.xlsx";
            a.click();
          });
      } else {
        fetch(
          this.$axios.defaults.baseURL +
            `rainfallRegime/year/export?rainName=${value}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "雨情年报表.xlsx";
            a.click();
          });
      }
    }
  },
  created() {
     siteGetSiteAll().then(res=>{
          this.waterList=res.data
         this.monthInfo.value=this.waterList[0].siteName
      })
    this.getList();
   // this.getData()
  },
  mounted() {}
};
</script>
<style lang='scss' scoped>
::v-deep .el-table {
  height: calc(530 / 1080 * 100vh) !important;
  margin-bottom: 15px!important;
  background: transparent;
}
.columnarChart{
  width: 100%;
  height: 230px;
  margin: 0px auto;
}
</style>
